<template>
  <b-card no-body class="work-allocation-table-card">
    <b-table
      responsive
      hover
      :items="data"
      :fields="fields"
      selectable
      select-mode="single"
      head-variant="light"
      table-class="work-allocation-table"
      sticky-header
    >
      <template v-slot:cell(completed)="data">
        <b-badge v-if="data.item.completed" variant="outline-danger">Lodged</b-badge>
        <b-badge v-else variant="outline-primary">Not lodged</b-badge>
      </template>
      <template v-slot:cell(claimAmount)="data">
        <span>{{ formatClaimAmount(data.item.claimAmount) }}</span>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { removeTrailingZeros } from '@/utils'
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    formatClaimAmount(claimAmount) {
      return removeTrailingZeros(parseFloat(claimAmount))
    }
  }
}
</script>

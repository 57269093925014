import { mapGetters } from 'vuex'
export const pusherMixin = {
  methods: {
    async subscribeChannel(pusher, channelName) {
      await pusher.subscribe(channelName)
    },
    async unsubscribeChannel(pusher, channelName) {
      await pusher.unsubscribe(channelName)
    },
    async bindEvent(pusher, eventName) {
      return new Promise((resolve) => {
        pusher.bind(eventName, (data) => {
          resolve(data)
        })
      })
    },
    async leaveChannels() {
      const pusher = window.Pusher

      const teamId = this.openTeamWeek?.team_id ?? null
      const currentSimulationId = this.currentSimulation?.id ?? null
      const currentUserId = this.currentUser?.id ?? null

      if (teamId) {
        await this.unsubscribeChannel(pusher, `week-control.${teamId}`)
        await this.unsubscribeChannel(pusher, `day-control.${teamId}`)
        await this.unsubscribeChannel(pusher, `game-control.${teamId}`)
        await this.unsubscribeChannel(pusher, `game-settings.${teamId}`)
        await this.unsubscribeChannel(pusher, `vmb.${teamId}`)
        await this.unsubscribeChannel(pusher, `performance.graphs.${teamId}`)
        await this.unsubscribeChannel(pusher, `performance.weekly-data.${teamId}`)
        await this.unsubscribeChannel(pusher, `performance.individual.${teamId}`)
        await this.unsubscribeChannel(pusher, `new_submitted_team.${teamId}`)
      }

      if (currentUserId && currentSimulationId) {
        await this.unsubscribeChannel(
          pusher,
          `work-allocation.${currentUserId}.${currentSimulationId}`
        )
        await this.unsubscribeChannel(pusher, `score-centre.${currentSimulationId}`)
      }
    }
  },
  computed: {
    ...mapGetters(['currentSimulation', 'currentUser', 'openTeamWeek'])
  },
  beforeDestroy() {
    this.leaveChannels()
  }
}

<template>
  <div class="allocations">
    <div
      class="m-5"
      v-if="workAllocations && openTeamWeek.work_complexity === 1 && inCurrentWeek && !initData"
    >
      <b-row class="h-100">
        <b-col class="col-12">
          <work-allocation-table
            :data="workAllocations.claimMasters"
            :fields="workAllocationLetterFields"
          ></work-allocation-table>
        </b-col>
      </b-row>
    </div>
    <div
      class="container"
      v-if="workAllocations && openTeamWeek.work_complexity === 2 && inCurrentWeek && !initData"
    >
      <b-row class="h-100">
        <b-colxx xxs="12" md="10" lg="10" class="mx-auto my-auto">
          <b-row>
            <b-col cols lg="12" md="12" sm="12">
              <div class="position-absolute slide-pagination">
                <b-pagination
                  v-model="paginationIndex"
                  :value="paginationIndex"
                  :total-rows="workAllocations.claimMasters.length"
                  :per-page="perPage"
                  @change="goSlide"
                  align="center"
                ></b-pagination>
              </div>
              <carousel-3d
                id="carousel"
                ref="carousel"
                :key="`work-allocation-letter-${updatedComponent}`"
                :inverseScaling="options.inverseScaling"
                :perspective="options.perspective"
                :space="options.space"
                :loop="options.loop"
                :height="options.height"
                :width="options.width"
                :display="options.display"
                :minSwipeDistance="650"
                @after-slide-change="afterSlideChange"
              >
                <slide
                  v-for="(item, index) in workAllocations.claimMasters"
                  :key="item.id"
                  :index="index"
                  :id="item.id"
                >
                  <work-allocation-letter :data="item"></work-allocation-letter>
                </slide>
              </carousel-3d>
            </b-col>
          </b-row>
        </b-colxx>
      </b-row>
    </div>
    <b-container
      class="vh-100"
      v-if="workAllocations && workAllocations.length === 0 && inCurrentWeek && !initData"
    >
      <b-row class="h-50 text-center" align-v="center">
        <b-col cols lg="6" md="6" sm="12" class="mx-auto img-container">
          <img src="/assets/img/svg/question.svg" alt="no record" />
          <h1>No work allocation found</h1>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="vh-100" v-if="!inCurrentWeek && !initData">
      <b-row class="h-50 text-center" align-v="center">
        <b-col cols lg="6" md="6" sm="12" class="mx-auto img-container">
          <img src="assets/img/svg/digital_nomad.svg" alt="no open day" />
          <h1>No open day yet</h1>
        </b-col>
      </b-row>
    </b-container>

    <ring-loader :loading="initData" :color="loader.color"></ring-loader>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Carousel3d, Slide } from 'vue-carousel-3d'
import { dataPreloadMixin } from '@/mixins/dataPreloadMixin'
import { helperMixin } from '@/mixins/helperMixin'
import { pusherMixin } from '@/mixins/pusherMixin'
import { workAllocationLetterFields } from '@/components/Common/config'
import RingLoader from 'vue-spinner/src/RingLoader'
import WorkAllocationLetter from '@/components/Common/WorkAllocationLetter'
import WorkAllocationTable from '@/components/Common/WorkAllocationTable'
export default {
  components: {
    Carousel3d,
    Slide,
    WorkAllocationLetter,
    WorkAllocationTable,
    RingLoader
  },
  data() {
    return {
      currentTeamWeek: null,
      inCurrentWeek: true,
      initData: true,
      loader: {
        color: '#2d7281'
      },
      loading: false,
      options: {
        inverseScaling: 200,
        perspective: 0,
        space: 300,
        loop: false,
        height: 850,
        width: 600,
        display: 3
      },
      paginationIndex: 1,
      perPage: 1,
      slideIndex: 0,
      updatedComponent: 0,
      workAllocationLetterFields
    }
  },
  mixins: [dataPreloadMixin, helperMixin, pusherMixin],
  methods: {
    ...mapActions([
      'completeWorkAllocation',
      'fetchAppConfiguration',
      'fetchOpenTeamWeek',
      'fetchSimulationByCode',
      'fetchWorkAllocations',
      'updateWorkComplexity'
    ]),
    afterSlideChange(index) {
      this.slideIndex = index
      this.paginationIndex = index + 1
    },
    goSlide(index) {
      this.slideIndex = index - 1
      this.$refs.carousel.goSlide(this.slideIndex)
    },
    async getLodgementData() {
      this.loading = true
      await this.getOpenTeamWeek()
      await this.getWorkAlloctions()
      await this.initiateListener()
      this.loading = false
    },
    async getOpenTeamWeek() {
      const currentSimulationTeamId = this.currentUser.simulations.find(
        (simulation) => simulation.id === this.currentSimulation.id
      )

      if (currentSimulationTeamId) {
        await this.fetchOpenTeamWeek({
          simulationId: this.currentSimulation.id,
          teamId: currentSimulationTeamId.simulationTeamId
        })
        this.currentTeamWeek = this.openTeamWeek
      }
    },
    async getWorkAlloctions() {
      if (this.currentTeamWeek) {
        await this.fetchWorkAllocations(this.currentTeamWeek.open_week_id)
        this.initData = false
        this.inCurrentWeek = true
        this.updatedComponent += 1
        this.paginationIndex = 1
      } else {
        this.initData = false
        this.inCurrentWeek = false
      }
    },
    async initiateListener() {
      await this.initiateWeekControlListener()
      await this.initiateDayControlListener()
      await this.initiateWorkAllocationListener()
      await this.initiateGameSettingsListener()
    },
    async initiateDayControlListener() {
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`day-control.${this.openTeamWeek.team_id}`)
      await channel.bind(
        'day-control',
        () => {
          this.getLodgementData()
        },
        channel.unbind()
      )
    },
    async initiateGameSettingsListener() {
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`game-settings.${this.openTeamWeek.team_id}`)
      await channel.bind(
        'game-settings',
        (data) => {
          const workComplexity = parseInt(data.work_complexity)
          const currentWorkComplexity = parseInt(this.openTeamWeek.work_complexity)
          if (workComplexity !== currentWorkComplexity) {
            this.updateWorkComplexity(workComplexity)
          }
        },
        channel.unbind()
      )
    },
    async initiateWeekControlListener() {
      let pusher = window.Pusher
      const channel = await pusher.subscribe(`week-control.${this.openTeamWeek.team_id}`)
      await channel.bind(
        'week-control',
        () => {
          this.getLodgementData()
        },
        channel.unbind()
      )
    },
    async initiateWorkAllocationListener() {
      let pusher = window.Pusher
      const channel = await pusher.subscribe(
        `work-allocation.${this.currentUser.id}.${this.currentSimulation.id}`
      )
      await channel.bind(
        'work-allocation',
        (data) => {
          if (data.allocation) {
            this.getWorkAlloctions()
          }

          // check if claim is completed and update record
          if (data.claim) {
            this.completeWorkAllocation(data.claim)
          }
        },
        channel.unbind()
      )
    }
  },
  computed: {
    ...mapGetters([
      'currency',
      'currentSimulation',
      'currentUser',
      'locale',
      'openTeamWeek',
      'workAllocations'
    ])
  },
  async created() {
    await this.fetchAppConfiguration()
  },
  watch: {
    $route: {
      async handler(to) {
        if (
          to &&
          this.checkWorkUrl(to.path) &&
          !this.currentSimulation &&
          to.name !== 'global-user-management'
        ) {
          await this.fetchSimulationByCode(this.code)
        }
      },
      immediate: true
    },
    currentSimulation: {
      handler(newValue) {
        if (newValue) {
          this.getLodgementData()
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.leaveChannels()
  }
}
</script>

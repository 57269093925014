import { mapActions } from 'vuex'
export const dataPreloadMixin = {
  methods: {
    ...mapActions([
      'fetchTeamWeeks',
      'fetchVmbAllData',
      'fetchVmbObservationData',
      'fetchVmbStatus',
      'updateVmbObservationStatus'
    ]),
    async getTeamWeeks(id) {
      await this.fetchTeamWeeks(id)
    },
    async getVmbData(simulationId) {
      await this.fetchVmbAllData(simulationId)
    },
    async getVmbObservationData(simulationId) {
      await this.fetchVmbObservationData(simulationId)
    },
    async getVmbStatus() {
      await this.fetchVmbStatus()
    }
  }
}

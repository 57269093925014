var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"work-allocation-letter-card",attrs:{"no-body":""}},[_c('b-card-body',[_c('b-container',[(_vm.data.completed)?_c('span',{class:{ 'stamp is-approved': _vm.data.completed }},[_vm._v("\n        "+_vm._s(_vm.$t('work-allocations.letter.completed'))+"\n      ")]):_vm._e(),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t('work-allocations.letter.content', {
            sitename: _vm.$t('general.sitename'),
            'policy-type': _vm.data.policyType,
            'claimed-type': _vm.data.claimedType,
            'claim-day': _vm.data.claimDay,
            'claim-amount': _vm.formatPayment(_vm.formatClaimAmount(_vm.data.claimAmount)),
            'policy-number': _vm.data.policyNumber,
            'policy-name': _vm.data.policyName,
            'no-of-items': _vm.data.noOfItems,
            'claimed-items': _vm.data.claimedItems,
            'given-name': _vm.data.givenName,
            surname: _vm.data.surname,
            'street-address': _vm.data.streetAddress,
            city: _vm.data.city,
            state: _vm.data.state,
            'zip-code': _vm.data.zipCode,
            'telephone-number': _vm.data.telephoneNumber
          })
        )}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }